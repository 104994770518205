import React, { memo, ReactElement, useCallback, useState } from 'react'

import Body from '@components/JourneyCard/Body'
import Details from '@components/JourneyCard/Details'
import Fares from '@components/JourneyCard/Details/Fares'
import Footer from '@components/JourneyCard/Footer'
import Header from '@components/JourneyCard/Header'
import Preview from '@components/JourneyCard/Media/Preview'
import useIsMobile from '@hooks/useIsMobile'
import fareUtils from '@lib/fare'
import fareClassUtils from '@lib/fareClass'
import { useTranslation } from '@lib/i18n'
import { UpdatedMediaData } from '@queries/media'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'
import { Card } from '@ui'

export interface JourneyCardLayoutProps {
  connection: Connection
  onDetailsOpen?: (connection: Connection) => void
  onSelect: (fareClass: string) => void
  fareClassFilter: string | null
  isInbound: boolean
  disableFareFilter: boolean
  media?: UpdatedMediaData[]
  forcedFreeTripPrice?: boolean
}

const JourneyCardLayout = (props: JourneyCardLayoutProps): ReactElement => {
  const {
    connection,
    onSelect,
    fareClassFilter,
    onDetailsOpen,
    isInbound,
    disableFareFilter,
    media,
    forcedFreeTripPrice,
  } = props
  const [{ pax, bookingId }] = useParams()
  const [{ fareClasses, vehicleIdentifier, carrierLogo, media: mediaSetting }] = useSettings()
  const isMobile = useIsMobile()
  const [opened, setOpened] = useState<boolean>(false)
  const [fareClass, setFareClass] = useState<string>(fareClassFilter ?? connection.cheapestFareClassCode)
  const { t } = useTranslation()
  const toggleDetails = (): void => {
    setOpened(!opened)
    if (!opened) {
      onDetailsOpen?.(connection)
    }
  }

  const handleSelect = useCallback(
    (fareClass: string) => {
      onSelect(fareClass)
      setFareClass(fareClass)
    },
    [onSelect],
  )

  const faresCondition = fareClasses.displayOn === 'search_results' || fareClasses.displayOn === 'everywhere'
  const filteredMedia = media?.flatMap(item => [
    ...item.images,
    ...item.videos,
    ...fareClassUtils.getMedia(item, fareClass),
  ])

  const isPreviewMediaShown = !isMobile && mediaSetting.preview && !!filteredMedia?.length
  const bestFare = fareUtils.getFareByCode(fareClassFilter ?? connection.cheapestFareClassCode, connection)
  const handleFareClassClick = (fareClass: string): void => {
    setOpened(true)
    setFareClass(fareClass)
  }

  return (
    <>
      <Card
        id={connection.id}
        key={connection.id}
        dataTag="connection-card"
        opened={opened}
        header={carrierLogo.enabled && <Header connection={connection} onClick={handleSelect} />}
        body={
          <Body
            onClick={handleSelect}
            connection={connection}
            fareSelection={faresCondition}
            lineDataEnabled={vehicleIdentifier.enabled}
            isHeaderHidden={!carrierLogo.enabled}
          />
        }
        footer={
          <Footer
            connection={connection}
            onToggleDetails={toggleDetails}
            onClick={handleSelect}
            passengersCount={pax}
            fare={bestFare}
            showFares={faresCondition}
            isAmendment={!!bookingId}
            isHeaderHidden={!carrierLogo.enabled}
            displayedFareClass={fareClass}
            forcedFreeTripPrice={forcedFreeTripPrice}
          />
        }
        details={
          <Details
            connection={connection}
            opened={opened}
            onToggleDetails={() => {
              setOpened(!opened)
            }}
            onClick={handleSelect}
            showFares={faresCondition}
            bestFare={bestFare}
            selectedFareClass={fareClassFilter}
            displayedFareClass={fareClass}
            onFareClick={handleFareClassClick}
            isInbound={isInbound}
            disableFareFilter={disableFareFilter}
            media={media}
            forcedFreeTripPrice={forcedFreeTripPrice}
          />
        }
        rightSide={
          faresCondition && (
            <Fares
              onClick={handleSelect}
              connection={connection}
              selectedFareClass={fareClassFilter}
              displayedFareClass={fareClass}
              onFareClick={handleFareClassClick}
              isInbound={isInbound}
              disableFareFilter={disableFareFilter}
            />
          )
        }
        leftSide={
          isPreviewMediaShown && (
            <Preview
              media={filteredMedia.slice(0, 2)}
              onClick={() => {
                setOpened(!opened)
              }}
            />
          )
        }
        disabled={connection.bookedOut}
        disabledLabel={t('card.booked')}
      />
    </>
  )
}

export default memo(JourneyCardLayout)
