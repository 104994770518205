import React, { ReactNode } from 'react'

import { DeckContext } from '@components/SeatSelection/DeckFrame/context'
import bem from '@lib/bem'

import '@components/SeatSelection/DeckFrame/index.scss'

interface Props {
  children: ReactNode
  orientation: Seat.DeckOrientation
  isTrainHead?: boolean
}

const DeckFrame = (props: Props) => {
  const { children, orientation, isTrainHead = false } = props
  return (
    <div className={bem('seat-selection', 'deck-wrapper', { [orientation]: true, head: isTrainHead })}>
      <div className={bem('seat-selection', 'deck')}>
        <DeckContext.Provider value={{ orientation, isTrainHead }}>{children}</DeckContext.Provider>
      </div>
    </div>
  )
}

export default DeckFrame
