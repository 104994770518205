import { createContext } from 'react'

interface DeckContext {
  orientation: Seat.DeckOrientation
  isTrainHead: boolean
}

export const orientationValue = {
  facingForward: 0,
  facingRight: 1,
  facingBackward: 2,
  facingLeft: 3,
}

export const DeckContext = createContext<DeckContext>({ orientation: 'right', isTrainHead: false })
