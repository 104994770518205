import React from 'react'

import bem from '@lib/bem'

import '@components/SeatSelection/Seat/Table/index.scss'

export interface TableSeatProps {
  position: Vector
  neighbors?: Seat.SeatOrientation[]
}

const TableSeat = ({ neighbors, position }: TableSeatProps) => (
  <div
    className={bem('seat-selection-table')}
    style={{
      gridRow: position.y + 1,
      gridColumn: position.x + 1,
    }}
  >
    <div className={bem('seat-selection-table', 'deck')}>
      {neighbors?.map(orientation => (
        <div key={orientation} className={bem('seat-selection-table', 'link', { [orientation]: true })} />
      ))}
    </div>
  </div>
)

export default TableSeat
