import cn from 'classnames'
import React, { useContext } from 'react'

import SegmentLabel from '@components/SeatSelection/SegmentLabel'
import TrainCarInfo from '@components/TrainSeatSelection/CarInfo'
import CarSelection from '@components/TrainSeatSelection/CarSelector'
import { TrainSeatSelectionContext } from '@components/TrainSeatSelection/context'
import TrainDeck from '@components/TrainSeatSelection/Deck'
import SelectedSeatsBreakdown from '@components/TrainSeatSelection/Desktop/SelectedSeatsBreakdown'
import TrainSubmitSection from '@components/TrainSeatSelection/SubmitSection'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import seatSelectionUtils from '@lib/seatSelection'
import { Dropdown } from '@ui'

import '@components/TrainSeatSelection/Desktop/index.scss'

const TrainSeatsDesktop = () => {
  const {
    connection,
    layout,
    fareClass,
    segmentsCount,
    currentCursor,
    fareOptions,
    setFareClass,
    carIndex,
    setCarIndex,
    disableFare,
    reservedSeatsCount,
    onSelect,
    selectedSeats,
    onSubmit,
    onNavigateBack,
    maxSegmentCount,
  } = useContext(TrainSeatSelectionContext)
  const { t } = useTranslation()
  const fare = connection.fares.find(fare => fare.fareClass.code === fareClass)
  const seatsPrice = seatSelectionUtils.sumPrice(selectedSeats)
  const handleChange = (fareClass: string): void => {
    setFareClass(fareClass)
    setCarIndex(0)
  }

  return (
    <div className="train-seats">
      <div className="row gap-10">
        <div className="cell basis-auto no-grow">
          <div className={cn('py-4 pr-4', bem('train-seats', 'deck-section'))}>
            <TrainCarInfo
              layout={layout[currentCursor]}
              connection={connection}
              carIndex={carIndex}
              fareClass={fareClass}
            />
            <TrainDeck
              seats={layout[currentCursor].cars[carIndex].seats}
              carIndex={carIndex}
              onClick={onSelect}
              selectedSeats={selectedSeats}
            />
          </div>
        </div>
        <div className="cell pb-sm-5">
          <div className="column pt-4 h-100">
            {segmentsCount > 1 && (
              <div className="cell no-grow pb-3">
                <SegmentLabel
                  segment={layout[currentCursor].segment}
                  segmentsCount={segmentsCount}
                  connection={connection}
                  currentIndex={currentCursor}
                  onNavigateBack={onNavigateBack}
                  maxSegmentCount={maxSegmentCount}
                />
              </div>
            )}
            <div className="cell no-grow pb-3">
              <Dropdown
                label={t('seats.fareClass')}
                disabled={disableFare}
                items={fareOptions}
                value={fareClass}
                onChange={handleChange}
              />
            </div>
            <div className="cell no-grow pb-4">
              <CarSelection layout={layout[currentCursor]} onChange={setCarIndex} value={carIndex} />
            </div>
            <div className="cell">
              <SelectedSeatsBreakdown fareClass={fare?.fareClass.name} seats={selectedSeats} max={reservedSeatsCount} />
            </div>
            <div className="cell no-grow">
              <TrainSubmitSection
                selectedSeats={selectedSeats}
                continueDisabled={selectedSeats.length !== reservedSeatsCount}
                skipVisible={selectedSeats.length === 0}
                seatsCount={reservedSeatsCount}
                fareClassName={fare?.fareClass.name}
                farePrice={fare?.price}
                seatsPrice={seatsPrice}
                onSubmit={onSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TrainSeatsDesktop
