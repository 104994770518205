import { SettingsResponse } from '@api/settings'
import config from '@config'

const getFixedClass = (
  retailerPartnerNumber: RetailerNumber,
  params: Record<string, any>,
  settings: SettingsResponse,
): string | null => {
  const carrierCode = params.marketingCarrierCode
  const isAmendment = params.bookingId
  const rpnSetting = config.forcedFareClass[retailerPartnerNumber]
  const carrierSetting = config.forcedFareClass[retailerPartnerNumber]?.[carrierCode]
  const supportList = config.forcedFareClass[retailerPartnerNumber]?.supported
  const isOneWay = params.returnDate == null && params.returnDepartureDate == null
  const isFareClassSelection = settings?.fareClasses.displayOn === 'search_results'

  const setting = carrierSetting ?? rpnSetting

  if (isFareClassSelection && supportList && params.fareClass) return params.fareClass
  if (!setting || isAmendment) return null
  if (isOneWay) return setting.oneWay
  else return setting.twoWay
}

const getCheckoutFixedClass = (
  retailerPartnerNumber: RetailerNumber,
  params: Record<string, any>,
  settings: SettingsResponse,
): string | null => {
  const setting = getFixedClass(retailerPartnerNumber, params, settings)
  const forcedDefaultClass = config.forcedFareClass[retailerPartnerNumber]?.checkout?.default
  const forcedDefinedClass = config.forcedFareClass[retailerPartnerNumber]?.checkout?.[params.fareClass]
  const weeklyForcedCheckoutClass = params.weekly && config.forcedFareClass[retailerPartnerNumber]?.weekly

  const supportList = config.forcedFareClass[retailerPartnerNumber]?.supported
  const isFareClassSelection = settings?.fareClasses.displayOn === 'search_results'

  if (supportList && isFareClassSelection && forcedDefinedClass) return forcedDefinedClass
  if (weeklyForcedCheckoutClass) return weeklyForcedCheckoutClass

  return forcedDefaultClass ?? setting
}

const getFixedClassSupportedList = (retailerPartnerNumber: RetailerNumber, isReturn: boolean): string[] | null => {
  const base = config.forcedFareClass[retailerPartnerNumber]
  const direction = isReturn ? 'twoWay' : 'oneWay'

  if (!base?.supported?.[direction]) return null

  return base.supported[direction]
}

const getMedia = (media: Media.Data, fareClass: string): string[] =>
  media.fare_classes?.reduce<string[]>(
    (acc, { code, images, videos }) => (code === fareClass ? [...acc, ...images, ...videos] : acc),
    [],
  )

const getYoutubeId = (url: string): string | undefined => {
  const regex = /(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/

  return url.match(regex)?.[1]
}

const validateVideoUrl = (url: string): boolean => {
  const regex = /\bhttps?:\/\/\S+\.(mp4|avi|mov|wmv|flv|mkv|webm)\b/

  return regex.test(url)
}

const fareClassUtils = {
  getFixedClass,
  getCheckoutFixedClass,
  getMedia,
  getYoutubeId,
  validateVideoUrl,
  getFixedClassSupportedList,
}

export default fareClassUtils
