import { FormikProps } from 'formik'
import { RefObject, useMemo } from 'react'
import { useParams as useRouterParams } from 'react-router'

import { LoadResponse as ReservationPaymentInfo, Reservation } from '@api/reservation'
import { PaymentPlatform } from '@api/reservation/confirm'
import { VacancyResponse } from '@api/vacancy'
import { SeatsList } from '@hooks/useSeatsController'
import ancillary, { AncillariesFormData } from '@lib/ancillary'
import fareClassUtils from '@lib/fareClass'
import passengerUtils from '@lib/passengers'
import reservationUtils from '@lib/reservation'
import { Vacancy } from '@lib/vacancy'
import { useInitialCheckoutPassenger } from '@pages/Checkout/hooks/useInitialCheckoutPassenger'
import { useSettings } from '@queries/settings'
import { ContactData, PassengerData } from '@stores/checkout'
import { useParams } from '@stores/params'

interface CardData {
  brand?: string
  bin?: number
  issuingCountryCode?: string
}

export interface VGSCardData {
  cardNumber: string
  cardExpiry: string
  cvv: string
  cardHolderName: string
}

type PaymentMethodData = VGSCardData | Record<string, unknown>

export type Seats = Record<ConnectionType, SeatsList>

interface InvoiceCommonProps {
  country: string
  state: string
  city: string
  address: string
  zipCode: string
}

export interface CompanyInvoice extends InvoiceCommonProps {
  type: 'company'
  businessName: string
  vatNumber: string
  taxCode: string
}

export interface IndividualInvoice extends InvoiceCommonProps {
  type: 'individual'
  firstName: string
  lastName: string
  fiscalCode: string
}

export type InvoiceData = CompanyInvoice | IndividualInvoice

export interface CheckoutFormData {
  passengers: PassengerData[]
  contact: ContactData
  termsAndPrivacy: boolean
  marketing: boolean
  vacancy: VacancyResponse | null
  vacancies: Vacancy[]
  isVacancyLoading: boolean
  paymentMethod: PaymentMethodType | null
  paymentProvider: PaymentProvider
  fareClass: string
  returnFareClass: string | null
  holderName?: string | null
  cpf?: string | null
  browserInfo?: object
  paymentMethodData?: PaymentMethodData
  cardData: CardData
  reservationData?: Reservation | null
  isReservationExpired: boolean | false
  isReservationLoading: boolean | false
  isBillingAddressLoading: boolean | false
  seats: Seats | null
  ancillaries: AncillariesFormData
  price: Money | null
  originalPrice: Money | null
  fees: Fee[]
  streetAndNumber?: string | null
  city?: string | null
  countryCode?: string | null
  state?: string | null
  zipCode?: string | null
  travelClass?: string
  returnTravelClass?: string
  cards: DiscountCardsState | null
  meta: {
    cards: DiscountCode.Card[]
    voucher: DiscountCode.Card[]
  }
  reservationInfo?: ReservationPaymentInfo
  reservationCode?: string
  numberOfInstallments: number
  installments: Installment.Item[]
  installment: Installment.Item | null
  deviceFingerprint: string | null
  priceError?: Error | null
  amendmentId?: string
  paymentAttemptReference: string
  shopperReference?: string | null
  blikCode?: number
  paymentToken?: string | null
  platform?: PaymentPlatform | null
  invoice?: InvoiceData | null
  marketingCarrierCode: string | null
}

export type CheckoutFormRef = RefObject<FormikProps<CheckoutFormData> | undefined | null>

type DiscountCardsState = Record<DiscountCode.Type, string>

const DISCOUNT_CARDS_INITIAL_STATE = {
  PROMOCODE: '',
}

export const useInitialFormValues = (): CheckoutFormData => {
  const { id } = useRouterParams()
  const [settings] = useSettings()
  const { paymentProvider, reservation, discountCodes, termsAndPrivacy, phone } = settings
  const [params] = useParams()
  const { passengers, pax, fareClass, seats, cards, returnFareClass, retailerPartnerNumber, marketingCarrierCode } =
    params
  const { filterPassengers } = passengerUtils
  const getInitialPassenger = useInitialCheckoutPassenger()
  const sessionAncillaries = reservationUtils.getSessionStorage(params)?.ancillaries
  const initialFareClass =
    fareClassUtils.getCheckoutFixedClass(retailerPartnerNumber, params, settings) ?? fareClass ?? ''
  const initialPhoneNumber = phone.enabled && params.phone ? params.phone : null

  const initialPassengers = useMemo(() => {
    if (passengers) {
      return filterPassengers(passengers).flatMap(passenger =>
        new Array(passenger.pax).fill(getInitialPassenger(passenger)),
      )
    }

    return [...Array(pax)].map(getInitialPassenger)
  }, [passengers, filterPassengers, pax, getInitialPassenger])

  const formCards = useMemo(() => {
    if (!discountCodes.enabled) return null
    if (!cards) return null

    return cards?.reduce((mem, item) => ({ ...mem, [item.name]: item.code }), DISCOUNT_CARDS_INITIAL_STATE)
  }, [discountCodes.enabled, cards])

  return useMemo(
    (): CheckoutFormData => ({
      passengers: initialPassengers,
      contact: { email: params.email ?? '', phone: initialPhoneNumber, confirmEmail: null },
      termsAndPrivacy: !termsAndPrivacy.enabled,
      marketing: false,
      vacancy: null,
      vacancies: [],
      isVacancyLoading: !reservation.enabled,
      fareClass: initialFareClass,
      returnFareClass,
      marketingCarrierCode,
      paymentMethod: null,
      paymentMethodData: {},
      paymentProvider: paymentProvider.code,
      seats: (seats as Seats) ?? null,
      holderName: '',
      cpf: '',
      cardData: {},
      reservationData: null,
      isReservationExpired: false,
      isBillingAddressLoading: false,
      isReservationLoading: reservation.enabled,
      ancillaries: sessionAncillaries ?? ancillary.getInitialFormData(),
      streetAndNumber: null as any,
      city: null,
      countryCode: null,
      state: null,
      zipCode: null,
      cards: formCards,
      meta: { cards: cards ?? [], voucher: [] },
      reservationCode: id,
      price: null,
      originalPrice: null,
      fees: [],
      numberOfInstallments: 1,
      installments: [],
      installment: null,
      deviceFingerprint: null,
      paymentAttemptReference: crypto.randomUUID(),
      platform: null,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
}
