import React, { ReactElement, useContext } from 'react'

import { DeckContext } from '@components/SeatSelection/DeckFrame/context'
import PassengerSeat, { PassengerSeatProps } from '@components/SeatSelection/Seat/Passenger'
import ServiceSeat, { LabeledSeatProps, serviceLabels, ServiceSeatCode } from '@components/SeatSelection/Seat/Service'
import TableSeat, { TableSeatProps } from '@components/SeatSelection/Seat/Table'
import vector from '@lib/vector'

import '@components/SeatSelection/Seat/index.scss'

interface PassengerSeatWithCode extends PassengerSeatProps {
  code: string
}

interface TableSeatWithCode extends TableSeatProps {
  code: 'TABLE'
}

type SeatProps = LabeledSeatProps | PassengerSeatWithCode | TableSeatWithCode

export const isServiceSeat = (props: SeatProps): props is LabeledSeatProps =>
  !!serviceLabels[props.code as ServiceSeatCode]

const isTableSeat = (props: SeatProps): props is TableSeatWithCode => props.code === 'TABLE'

const SeatComponent = (props: SeatProps): ReactElement => {
  const { position } = props
  const deck = useContext(DeckContext)
  const rotatedPosition = deck.orientation === 'up' ? vector.swap(position) : position

  if (isTableSeat(props)) {
    return <TableSeat {...props} position={rotatedPosition} />
  }

  if (isServiceSeat(props)) {
    return <ServiceSeat position={rotatedPosition} orientation={deck.orientation} code={props.code} />
  }

  return <PassengerSeat {...props} position={rotatedPosition} />
}

export default SeatComponent
