import config from '@config'
import { Sorting } from '@enums'
import date from '@lib/date'
import { createStore, useStore, UseStoreHook } from '@lib/store'

interface BaseParamsStore {
  arrivalLocation: Location.Item | null
  departureLocation: Location.Item | null
  departureDate: string
  departureTime: string | null
  departureEndTime: string | null
  arrivalDate: string | null
  arrivalTime: string | null
  returnDepartureDate: string | null
  returnDepartureTime: string | null
  returnArrivalDate: string | null
  returnArrivalTime: string | null
  fareClass: string | null
  returnFareClass: string | null
  locale: Locale
  currency: Currency
  pax: number
  retailerPartnerNumber: RetailerNumber
  returnDate: string | null
  sorting: Sorting | null
  seats: Seat.TripParams | null
  mode?: PageMode
  express: boolean
  passengers: Passenger.Param[] | null
  passengersCards: string[]
  cards: DiscountCode.Card[] | null
  price: number | null
  retailerBookingNumber?: string | null
  bookingId?: string | null
  parentDomain?: string | null
  email?: string | null
  phone?: string | null
  hideLocations: boolean
  weekly?: boolean | null
  connections?: WeeklyConnectionsParams
  offers?: string | null
  seo?: boolean | null
}

export interface ParamsStore extends BaseParamsStore {
  marketingCarrierCode: string | null
}

export interface CarrierSpecificParamsStore extends BaseParamsStore {
  marketingCarrierCode: string
}

const store = createStore<any>({
  arrivalLocation: null,
  departureLocation: null,
  departureDate: date.formatDate(new Date()),
  departureTime: null,
  departureEndTime: null,
  arrivalDate: null,
  arrivalTime: null,
  returnDepartureDate: null,
  returnDepartureTime: null,
  returnArrivalDate: null,
  returnArrivalTime: null,
  fareClass: null,
  returnFareClass: null,
  locale: config.fallback.locale.default,
  currency: config.fallback.currency.default,
  pax: config.fallback.pax,
  retailerPartnerNumber: 0,
  returnDate: null,
  sorting: null,
  seats: null,
  mode: 'page',
  express: false,
  passengers: null,
  passengersCards: [],
  cards: null,
  price: null,
  retailerBookingNumber: null,
  hideLocations: false,
})

export const useParams = (): UseStoreHook<ParamsStore> => useStore(store)
export const useCarrierSpecificParams = (): UseStoreHook<CarrierSpecificParamsStore> => useStore(store)

export default store
