import React, { useMemo } from 'react'

import DeckFrame from '@components/SeatSelection/DeckFrame'
import SeatComponent from '@components/SeatSelection/Seat'
import seatSelectionUtils from '@lib/seatSelection'
import vector from '@lib/vector'

interface Props {
  seats: Seat.Entry[]
  carIndex: number
  onClick: (seat: Seat.Entry) => void
  selectedSeats: Seat.Entry[]
}

const sides: Seat.Direction[] = ['straight', 'right', 'back', 'left']
const tableKey = (seat: Seat.Entry): string => vector.toKey(vector.swap(seat.coordinates))

const getNeighbors = (seat: Seat.Entry, cells: Set<string>): Seat.Direction[] =>
  sides
    .map(side => {
      const key = vector.toKey(vector.step[side](vector.swap(seat.coordinates)))

      return cells.has(key) ? side : null
    })
    .filter(Boolean) as Seat.Direction[]

const buildTablesSet = (seats: Seat.Entry[]): Set<string> => {
  const tables = new Set<string>()

  seats.forEach(seat => {
    if (seat.code === 'TABLE') tables.add(tableKey(seat))
  })

  return tables
}

const TrainDeck = (props: Props) => {
  const { seats, onClick, selectedSeats, carIndex } = props

  const tables = useMemo(() => buildTablesSet(seats), [seats])

  const tableProps = (seat: Seat.Entry) => ({ neighbors: getNeighbors(seat, tables) })
  const priceCategories = seatSelectionUtils.getPriceCategories(seats)

  return (
    <DeckFrame orientation="up" isTrainHead={carIndex === 0}>
      {seats.map(seat => (
        <SeatComponent
          position={seat.coordinates}
          key={seat.id}
          code={seat.code}
          label={seat.label}
          disabled={!seat.vacant}
          price={seat.price}
          orientation={seat.orientation}
          priceCategory={priceCategories[seat.price ?? 0]}
          onSelect={() => onClick({ ...seat, carIndex })}
          selected={selectedSeats.some(item => item.id === seat.id)}
          {...(seat.code === 'TABLE' ? tableProps(seat) : undefined)}
        />
      ))}
    </DeckFrame>
  )
}

export default TrainDeck
