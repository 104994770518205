import React from 'react'

import useIsMobile from '@hooks/useIsMobile'
import { Button } from '@ui'
import ButtonGroup from '@ui/ButtonGroup'

import '@components/TrainSeatSelection/CarSelector/index.scss'

interface Props {
  layout: Seat.Data
  onChange: (carIndex: number) => void
  value: number
}

const CarSelection = (props: Props) => {
  const { layout, value, onChange } = props
  const isMobile = useIsMobile()

  return (
    <div className="train-seats-car-selector">
      <ButtonGroup flat={isMobile}>
        {layout.cars.map(({ index }) => (
          <Button
            key={index}
            onClick={() => onChange(index)}
            variant={value === index ? 'filled' : 'outline'}
            color={value === index ? 'secondary' : 'grey'}
          >
            {index + 1}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  )
}

export default CarSelection
