import React from 'react'

import luggage from '@images/extras/luggage.svg'
import restaurant from '@images/extras/restaurant.svg'
import stairs from '@images/extras/stairs.png'
import wheel from '@images/extras/steering-wheel.png'
import stroller from '@images/extras/stroller.svg'
import wc from '@images/extras/wc.svg'
import wheelchair from '@images/extras/wheelchair.svg'
import bem from '@lib/bem'

import '@components/SeatSelection/Seat/Service/index.scss'

export const serviceLabels = {
  ES: stairs,
  WHEEL: wheel,
  WC: wc,
  STROLLER: stroller,
  WHEELCHAIR: wheelchair,
  RESTAURANT: restaurant,
  LUGGAGE: luggage,
}

export type ServiceSeatCode = keyof typeof serviceLabels

export interface LabeledSeatProps {
  position: Vector
  code: ServiceSeatCode
  orientation: Seat.DeckOrientation
}

const ServiceSeat = ({ position, code, orientation }: LabeledSeatProps) => {
  const gridPlacement = { gridRow: position.y + 1, gridColumn: position.x + 1 }
  const stairs = code === 'ES'

  return (
    <div className={bem('seat-selection-service-seat', { stairs })} style={{ ...gridPlacement }}>
      <img
        src={serviceLabels[code]}
        alt={code}
        className={bem('seat-selection', 'labeled-seat', { code, [orientation]: true })}
      />
    </div>
  )
}

export default ServiceSeat
