import { buildBookingEvent, BookingDataEvent } from '@lib/analytics/gtm/confirmation/bookingEvent'
import { buildCheckoutEvent, CheckoutEvent, CheckoutEventParams } from '@lib/analytics/gtm/confirmation/checkoutEvent'
import {
  buildConnectionResultsEvent,
  ConnectionResultsEvent,
  ConnectionResultsEventParams,
} from '@lib/analytics/gtm/confirmation/connectionResultsEvent'
import { buildPurchaseEvent, PurchaseEvent } from '@lib/analytics/gtm/confirmation/purchaseEvent'
import utils from '@lib/utils'

type GTMEvent = PurchaseEvent | BookingDataEvent | ConnectionResultsEvent | CheckoutEvent

const pushBookingSucceedEvent = async (bookingData: Booking.Confirmation, locale: Locale): Promise<void> => {
  try {
    const event = await buildBookingEvent(bookingData, locale)

    GTMDataLayer.push(utils.object.compact(event))
  } catch (error) {
    /* istanbul ignore next */
    console.log(error)
  }
}

const pushPurchaseSucceedEvent = (bookingData: Booking.Confirmation, currency: Currency): void => {
  try {
    const event = buildPurchaseEvent(bookingData, currency)

    GTMDataLayer.push(event)
  } catch (error) {
    /* istanbul ignore next */
    console.log(error)
  }
}

const pushConnectionResultsEvent = (params: ConnectionResultsEventParams): void => {
  try {
    const event = buildConnectionResultsEvent(params)

    GTMDataLayer.push(event)
  } catch (error) {
    /* istanbul ignore next */
    console.log(error)
  }
}

const pushCheckoutEvent = (params: CheckoutEventParams): void => {
  try {
    const event = buildCheckoutEvent(params)

    GTMDataLayer.push(event)
  } catch (error) {
    /* istanbul ignore next */
    console.log(error)
  }
}

declare global {
  interface Window {
    dataLayer: GTMEvent[]
  }
}

const GTMDataLayer = (() => {
  window.dataLayer = window.dataLayer || []

  const push = (event: GTMEvent) => window.dataLayer.push(event)

  return { push }
})()

export default {
  pushBookingSucceedEvent,
  pushPurchaseSucceedEvent,
  pushConnectionResultsEvent,
  pushCheckoutEvent,
}
