import React, { useMemo, useState } from 'react'

import { TrainSeatSelectionContext } from '@components/TrainSeatSelection/context'
import TrainSeatsDesktop from '@components/TrainSeatSelection/Desktop'
import Header from '@components/TrainSeatSelection/Header'
import TrainSeatsMobile from '@components/TrainSeatSelection/Mobile'
import TrainSubmitSection from '@components/TrainSeatSelection/SubmitSection'
import useIsMobile from '@hooks/useIsMobile'
import fare from '@lib/fare'
import seatSelectionUtils from '@lib/seatSelection'
import { Modal } from '@ui'

export interface Props {
  opened: boolean
  layout: Seat.Data[]
  selectedSeats: Seat.Entry[]
  connection: Connection
  reservedSeatsCount: number
  currentCursor: number
  onSelect: (seat: Seat.Entry) => void
  onSubmit: (passengerCard?: DiscountCard.Item | null) => void
  onClose: () => void
  onFareClassChange: (fareClass: string) => void
  selectedFareClassCode: string
  disableFare?: boolean
  onNavigateBack?: () => void
}

const TrainSeatSelection = (props: Props) => {
  const {
    opened,
    connection,
    selectedFareClassCode,
    onClose,
    reservedSeatsCount,
    layout,
    selectedSeats,
    onSubmit,
    currentCursor,
    onFareClassChange,
    onSelect,
    disableFare,
    onNavigateBack,
  } = props
  const isMobile = useIsMobile()

  const fareOptions = useMemo(
    () =>
      fare
        .getUniqueFareClasses([connection], {})
        .map(({ code, name }) => ({ value: code, label: name }))
        .sort((a, b) => a.value.localeCompare(b.value, undefined, { numeric: true })),
    [connection],
  )

  const [carIndex, setCarIndex] = useState(0)
  const segmentsCount = connection.segments.length
  const maxSegmentCount = layout.length
  const fareClassName = fareOptions.find(({ value }) => value === selectedFareClassCode)?.label
  const seats = layout[currentCursor].cars[carIndex].seats
  const farePrice = connection.fares.find(fare => fare.fareClass.code === selectedFareClassCode)?.price
  const seatsPrice = seatSelectionUtils.sumPrice(selectedSeats)
  const handleSubmit = (): void => {
    setCarIndex(0)
    onSubmit()
  }

  return (
    <Modal
      title={<Header seats={seats} hasSelectedSeats={selectedSeats.length > 0} />}
      opened={opened}
      onClose={onClose}
      footer={
        isMobile && (
          <TrainSubmitSection
            selectedSeats={selectedSeats}
            continueDisabled={selectedSeats.length !== reservedSeatsCount}
            seatsCount={reservedSeatsCount}
            skipVisible={selectedSeats.length === 0}
            fareClassName={fareClassName}
            farePrice={farePrice}
            seatsPrice={seatsPrice}
            onSubmit={handleSubmit}
          />
        )
      }
      fullScreen
      fullWidth
      fullHeight
      maxWidth="lg"
      className="train-seats-modal"
    >
      {layout && (
        <TrainSeatSelectionContext.Provider
          value={{
            fareClass: selectedFareClassCode,
            segmentsCount,
            fareOptions,
            setFareClass: onFareClassChange,
            carIndex,
            setCarIndex,
            onSubmit: handleSubmit,
            onSelect,
            selectedSeats,
            opened,
            connection,
            layout,
            currentCursor,
            disableFare,
            reservedSeatsCount,
            onNavigateBack,
            maxSegmentCount,
          }}
        >
          {isMobile ? <TrainSeatsMobile /> : <TrainSeatsDesktop />}
        </TrainSeatSelectionContext.Provider>
      )}
    </Modal>
  )
}

export default TrainSeatSelection
