import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import Money from '@components/Money'
import { orientationValue } from '@components/SeatSelection/DeckFrame/context'
import useIsMobile from '@hooks/useIsMobile'
import useIsTablet from '@hooks/useIsTablet'
import bem from '@lib/bem'
import { Trans, useTranslation } from '@lib/i18n'
import seat from '@lib/seatSelection'
import utils from '@lib/utils'
import { useParams } from '@stores/params'
import { Button, Icon, Tooltip } from '@ui'

export interface PassengerSeatProps {
  position: Vector
  price?: number | null
  priceCategory?: number | null
  discountCategory?: DiscountCard.Item[]
  discountIndex?: number
  label?: string
  selected?: boolean
  disabled: boolean
  onSelect?: () => void
  isDriverSeat?: boolean
  orientation?: Seat.SeatOrientation | null
  isFirstSeat?: boolean
}

const DISCOUNT_SIGN = '%'
const isInView = (element: HTMLDivElement): boolean => window.innerHeight > element.getBoundingClientRect().top

const PassengerSeat = (props: PassengerSeatProps) => {
  const {
    position,
    price,
    discountIndex,
    priceCategory,
    label,
    onSelect,
    disabled,
    selected,
    isDriverSeat,
    discountCategory,
    orientation = 'facingForward',
    isFirstSeat,
  } = props
  const [{ currency }] = useParams()
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const isSmallScreen = isMobile || isTablet
  const isVacant = !disabled && !selected
  const [opened, setOpened] = useState<boolean | undefined>(isSmallScreen ? false : undefined)
  const ref = useRef<HTMLDivElement>(null)
  /* istanbul ignore next: unstable scroll behavior in Cypress */
  const scroll = useCallback(() => {
    ref.current && !isInView(ref.current) && ref.current.scrollIntoView({ behavior: 'smooth' })
  }, [])

  useEffect(() => {
    if (!isMobile || !isFirstSeat) return
    // workaround for Safari
    const timeout = setTimeout(scroll, 250)

    return () => clearTimeout(timeout)
  }, [isFirstSeat, isMobile, scroll])

  const paid = price != null && priceCategory != null
  const discounted = useMemo(() => !!discountCategory?.length, [discountCategory])
  const iconsCount = useMemo(() => {
    if (!paid) return 0
    if (disabled || selected) return 1

    return priceCategory
  }, [paid, disabled, selected, priceCategory])

  const tooltipContent = useMemo(() => {
    if (!price) return null
    if (disabled && isSmallScreen) return null

    return (
      <div className={bem('seat-selection', 'seat-tooltip')}>
        <div>
          <Trans i18nKey="seats.seat" values={{ label }} components={{ 0: <b /> }} />
        </div>
        <span>
          +<Money fractional={price} currency={currency} />
        </span>
        {isSmallScreen && (
          <Button
            color="secondary"
            onClick={() => {
              onSelect?.()
              setOpened(!opened)
            }}
          >
            {t(`seats.${selected ? 'deselect' : 'select'}`)}
          </Button>
        )}
      </div>
    )
  }, [price, disabled, t, label, currency, isSmallScreen, selected, onSelect, opened])

  const handleClick = (): void => {
    if (!isSmallScreen || !paid) {
      onSelect?.()
    }
  }

  const gridPlacement = { gridRow: position.y + 1, gridColumn: position.x + 1 }

  useEffect(() => {
    if (!ref.current || !orientation) return
    const rotation = 90 * orientationValue[orientation]

    ref.current.style.setProperty('--individual-rotation', `${rotation}deg`)
  }, [orientation])

  return (
    <Tooltip
      action={isSmallScreen ? 'click' : 'hover'}
      position="top"
      className={bem('seat-selection', 'seat-wrapper')}
      popperClassName={bem('seat-selection', 'seat-tooltip-wrapper')}
      content={tooltipContent}
      style={{ ...gridPlacement }}
      opened={opened}
      onOpenChanged={setOpened}
    >
      <div
        ref={ref}
        style={{ ...gridPlacement }}
        className={bem('seat-selection', 'seat', { selected, disabled, paid, discounted })}
        onClick={handleClick}
      >
        <div className={bem('seat-selection', 'seat-surface', { left: true })} />
        <div className={bem('seat-selection', 'seat-armrest', { left: true })} />
        <div className={bem('seat-selection', 'seat-armrest', { right: true })} />
        <div className={bem('seat-selection', 'seat-armrest', { back: true })}>
          {isVacant &&
            utils.common.times(iconsCount, index => (
              <Icon
                className={bem('seat-selection', 'seat-sign')}
                key={index}
                name={seat.getCurrencyIcon(currency)}
                size="small"
              />
            ))}
          {discountCategory?.[0] && (
            <span className={bem('seat-selection', 'seat-discount', { disabled })}>
              <span className={bem('seat-selection', 'seat-discount-sign')}>{DISCOUNT_SIGN}</span>
              <span className={bem('seat-selection', 'seat-discount-index')}>{discountIndex}</span>
            </span>
          )}
          {!isVacant && <span className={bem('seat-selection', 'seat-armrest-label')}>{label}</span>}
        </div>
        {isVacant && <div className={bem('seat-selection', 'seat-label', { selected })}>{label}</div>}
        {disabled && !isDriverSeat && <Icon name="cross" size="large" />}
        {selected && (
          <span className={bem('seat-selection', 'selected-icon')}>
            <Icon name="checkout" size="large" />
          </span>
        )}
      </div>
    </Tooltip>
  )
}

export default PassengerSeat
