import { BookingConfirmationResponse } from '@api/booking'

interface IncludedCustomFields {
  id: string
  type: string
  attributes: {
    name: CustomFieldName
    value: string
  }
}

const transformResponse = (response: any, rawResponse: any): BookingConfirmationResponse => {
  if (rawResponse?.jsonapi?.version == null) return rawResponse

  const booking = {
    id: response.id,
    createdAt: response.createdAt,
    email: response.email,
    totalPrice: response.totalPrice,
    pax: response.pax,
    distribusionBookingNumber: response.distribusionBookingNumber,
    marketingCarrierBookingNumber: response.marketingCarrierBookingNumber,
    retailerPartnerNumber: response.retailerPartnerNumber,
    installment: response.installment,
    installments: response.installments,
    segments: response.segments,
    fareClass: response.fareClass,
    departureStation: response.departureStation,
    arrivalStation: response.arrivalStation,
    paymentMethod: response.paymentMethod,
    ancillaries: response.ancillaries,
    fees: response.fees,
    status: response.status,
    marketingCarrierCode: response.marketingCarrier.code,
    returnFareClass: null,
    phone: response.phone,
  }

  return {
    booking: booking,
    marketingCarrier: response.marketingCarrier,
    passengers: response.passengers,
    outboundConnection: response.outboundConnection,
    inboundConnection: response.inboundConnection,
    customFields: rawResponse.included.reduce(
      (acc: Booking.CustomFields[], curr: IncludedCustomFields) =>
        curr.type === 'custom_fields' ? [...acc, curr.attributes] : acc,
      [],
    ),
  }
}

export { transformResponse }
