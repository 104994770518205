import { AmendmentCreateRequest } from '@api/amendments'
import { BookingResponse } from '@api/booking'
import { SearchFormData, SearchFormSettings } from '@components/SearchForm'
import { DirectionType } from '@components/TripDirection'
import amendmentUtils from '@lib/amendment'
import { PASSENGERS_CODE } from '@lib/passengers'
import utils from '@lib/utils'
import { useSettings } from '@queries/settings'
import { PassengerData } from '@stores/checkout'
import { ParamsStore, useParams } from '@stores/params'

interface MoovitIntegrationHook {
  isJJKEnabled: boolean
  updateJJKTypesList: (list: Passenger.Type[]) => Passenger.Type[]
  filterJJKTypes: (list: Passenger.Type[], direction: DirectionType) => Passenger.Type[]
  updateJJKPassengers: (passengers: PassengerData[]) => PassengerData[]
  isYACPassengerType: (types: string) => boolean
  amendmentJJKSettings: SearchFormSettings
  getIsJJKAmendable: (rules?: Amendment.Rule[]) => boolean
  updateAmendmentInitialValues: (values: SearchFormData) => SearchFormData
  updateAmendmentCreateParams: (
    booking: BookingResponse,
    params: ParamsStore,
    data: AmendmentCreateRequest,
  ) => AmendmentCreateRequest
}

const JJK_RPN = [756974, 206843]
const YOUNG_ADULT_CODE = 'YAC'
const FAMILY_TYPE_CODE = 'PFTY'
const FREE_SEAT_TYPE_CODE = 'PSER'

const SORT_ORDER = [
  PASSENGERS_CODE.adult,
  FREE_SEAT_TYPE_CODE,
  YOUNG_ADULT_CODE,
  PASSENGERS_CODE.teen,
  PASSENGERS_CODE.child,
  FAMILY_TYPE_CODE,
]

const useKavanaghIntegration = (): MoovitIntegrationHook => {
  const [{ retailerPartnerNumber }] = useParams()
  const [{ tripTime, discountCodes }] = useSettings()

  const sortTypesList = (list: Passenger.Type[]): Passenger.Type[] => {
    const orderMap = new Map(SORT_ORDER.map((item, index) => [item, index]))

    return list.sort((a, b) => (orderMap.get(a.code) ?? 0) - (orderMap.get(b.code) ?? 0))
  }

  const updateJJKTypesList = (list: Passenger.Type[]): Passenger.Type[] => {
    const youngAdultType = {
      code: YOUNG_ADULT_CODE,
      name: 'Young Adult Card',
      minAge: 0,
      maxAge: 97,
      id: YOUNG_ADULT_CODE,
      description: '',
      cards: [{ name: YOUNG_ADULT_CODE, code: 'Please enter your card number' }],
    }

    return sortTypesList([...list, youngAdultType])
  }

  const filterJJKTypes = (list: Passenger.Type[], direction: DirectionType): Passenger.Type[] => {
    if (direction === 'weekly')
      return list.filter(({ code }) => ![FAMILY_TYPE_CODE, FREE_SEAT_TYPE_CODE].includes(code))

    if (direction === 'oneWay') return list.filter(({ code }) => ![FAMILY_TYPE_CODE].includes(code))

    return list
  }

  const updateJJKPassengers = (passengers: PassengerData[]): PassengerData[] =>
    passengers.map(passenger => {
      if (passenger.type === YOUNG_ADULT_CODE) return { ...passenger, type: 'PNOS' }

      return passenger
    })

  const amendmentJJKSettings: SearchFormSettings = {
    returnTrip: false,
    passengerType: false,
    tripTime: tripTime.enabled,
    discountCodes: discountCodes.enabled,
    paxDiscountCodes: false,
    accommodation: false,
    weekly: false,
    disabledLocation: true,
  }

  const getIsJJKAmendable = (rules?: Amendment.Rule[]): boolean => {
    if (!rules) return false
    const { filterRule } = amendmentUtils

    return [...filterRule(rules, 'tripDetails'), ...filterRule(rules, 'general')].some(({ allowed }) => allowed)
  }

  const updateAmendmentInitialValues = (values: SearchFormData): SearchFormData => ({
    ...values,
    returnDate: null,
  })

  const getConnectionTime = (time: string | null, isOutbound: boolean): string | null => (isOutbound ? time : null)

  const updateAmendmentCreateParams = (
    booking: BookingResponse,
    params: ParamsStore,
    data: AmendmentCreateRequest,
  ): AmendmentCreateRequest => {
    const { outboundConnection } = booking
    const { departureLocation, arrivalLocation } = params
    const { paramsDate } = amendmentUtils

    const isOutbound =
      outboundConnection.departureStation.code === departureLocation?.code &&
      outboundConnection.arrivalStation.code === arrivalLocation?.code

    const parsedDepartureTime = paramsDate(params.departureDate, params.departureTime)
    const parsedArrivalTime = paramsDate(params.arrivalDate, params.arrivalTime)

    return utils.object.compact<AmendmentCreateRequest>({
      ...data,
      departureTime: getConnectionTime(parsedDepartureTime, isOutbound),
      arrivalTime: getConnectionTime(parsedArrivalTime, isOutbound),
      returnDepartureTime: getConnectionTime(parsedDepartureTime, !isOutbound),
      returnArrivalTime: getConnectionTime(parsedArrivalTime, !isOutbound),
      departureStationCode: null,
      arrivalStationCode: null,
      fareClass: null,
    })
  }

  return {
    isJJKEnabled: JJK_RPN.includes(retailerPartnerNumber),
    isYACPassengerType: (type: string) => type === YOUNG_ADULT_CODE,
    updateJJKTypesList,
    updateJJKPassengers,
    filterJJKTypes,
    amendmentJJKSettings,
    getIsJJKAmendable,
    updateAmendmentInitialValues,
    updateAmendmentCreateParams,
  }
}

export default useKavanaghIntegration
