import cn from 'classnames'
import { useFormikContext } from 'formik'
import React, { ReactElement } from 'react'

import { SearchFormState } from '@components/SearchForm'
import LocationField from '@components/SearchForm/LocationField'
import config from '@config'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import { useParams } from '@stores/params'
import { Button, Icon } from '@ui'

const LAYOUT_CLASS_NAMES = {
  row: {
    locationsContainer: ['row', 'gap-3'],
    departureContainer: ['cell-6', 'grow'],
    arrivalContainer: ['cell-6', 'grow'],
  },
  column: {
    locationsContainer: ['column', 'gap-5'],
    departureContainer: ['cell'],
    arrivalContainer: ['cell'],
  },
}

interface LocationFieldSetProps {
  direction: Direction
  disabled?: boolean
}

const getFilterCodes = (rpn: number, location: Location.NamedItem | null): string[] => {
  const { code = '', countryCode } = { ...location }
  const { suggestions } = config

  if (suggestions[rpn]?.filter.byCities.includes(code)) return suggestions[rpn]?.filter.byCountries

  return suggestions[rpn]?.filter.byCountries.filter(item => item === countryCode) ?? []
}

const filterSuggestionByCities =
  (rpn: number, location: Location.NamedItem | null) =>
  (data: Location.Suggestion[] | null): Location.Suggestion[] => {
    if (!data) return []

    const list = getFilterCodes(rpn, location).length ? config.suggestions[rpn]?.filter.byCities : []

    return data.filter(item => !list.includes(item.code))
  }

const LocationFieldSet = ({ direction, disabled }: LocationFieldSetProps): ReactElement => {
  const classNames = LAYOUT_CLASS_NAMES[direction]
  const { t } = useTranslation()
  const {
    values: { departureLocation, arrivalLocation },
    setFieldValue,
  } = useFormikContext<SearchFormState>()
  const [{ retailerPartnerNumber, bookingId }] = useParams()
  const switchLocations = (): void => {
    setFieldValue('departureLocation', arrivalLocation, false)
    setFieldValue('arrivalLocation', departureLocation, false)
  }

  const configModal = {
    icon: <Icon name="search" size="large" />,
    placeholder: t('searchBar.autosuggestion.placeholder'),
  }

  const config = {
    departure: {
      default: { label: t('searchBar.departureStationLabel'), icon: <Icon name="navigation" size="large" /> },
      modal: { ...configModal, label: t('searchBar.departureStationLabel') },
    },
    arrival: {
      default: { label: t('searchBar.arrivalStationLabel'), icon: <Icon name="map-pin" size="large" /> },
      modal: { ...configModal, label: t('searchBar.arrivalStationLabel') },
    },
  }

  const locationDisabled = disabled ?? !!bookingId

  return (
    <div className={cn(...classNames.locationsContainer)}>
      <div className={bem('search-form', 'switch-locations')}>
        <Button onClick={switchLocations} variant="filled" color="secondary" round disabled={locationDisabled}>
          <Icon name={direction === 'column' ? 'switch' : 'return'} size="medium" />
        </Button>
      </div>
      <div className={cn(...classNames.departureContainer)}>
        <LocationField
          name="departureLocation"
          fieldType="departure"
          filterCodes={getFilterCodes(retailerPartnerNumber, arrivalLocation)}
          filterSuggestion={filterSuggestionByCities(retailerPartnerNumber, arrivalLocation)}
          disabled={locationDisabled}
          config={config.departure}
        />
      </div>
      <div className={cn(...classNames.arrivalContainer)}>
        <LocationField
          name="arrivalLocation"
          fieldType="arrival"
          departureLocation={departureLocation}
          filterCodes={getFilterCodes(retailerPartnerNumber, departureLocation)}
          filterSuggestion={filterSuggestionByCities(retailerPartnerNumber, departureLocation)}
          disabled={locationDisabled}
          config={config.arrival}
        />
      </div>
    </div>
  )
}

export default LocationFieldSet
