import makeApi from '@lib/api'

export type SeatsResponse = Seat.Data[]

export interface SeatsRequest {
  marketingCarrierCode: string
  arrivalStation?: string
  departureStation?: string
  departureTime: string | null
  arrivalTime: string | null
  locale: string
  currency: string
  extraFields?: string
  fareClass?: string | null
}

const findMeta = (array: any, id: string, limitId: string) => {
  const limitations = array.included.find((item: any) => item.id === id)?.relationships?.limitations?.data

  return limitations.find((item: any) => item.id === limitId)?.meta
}

const findLimitations = <T extends { id: string }>(array: T[] | undefined, id: string, rawResponse: any) =>
  array?.map(limit => ({ ...limit, meta: findMeta(rawResponse, id, limit.id) })) ?? /* istanbul ignore next */ []

const transformResponse = (response: SeatsResponse, rawResponse: any, requestParams: SeatsRequest): SeatsResponse => {
  if (rawResponse?.jsonapi?.version == null) return rawResponse
  if (!requestParams.extraFields) return response

  return response.map(item => ({
    ...item,
    cars: item.cars.map(car => ({
      ...car,
      limitations: findLimitations(car.limitations, car.id, rawResponse),
      seats: car.seats.map(seat => ({ ...seat, limitations: findLimitations(seat.limitations, seat.id, rawResponse) })),
    })),
  }))
}

export default {
  load: makeApi.get<SeatsRequest, SeatsResponse>(
    { type: 'seats', old: 'bookings/seats', new: '/seats' },
    {
      transformResponse: <T, U, P>(response: T, rawResponse: U, requestParams: P) =>
        transformResponse(response as SeatsResponse, rawResponse, requestParams as SeatsRequest) as T,
    },
  ),
}
