import { createContext } from 'react'

interface TrainSeatSelectionContext {
  opened: boolean
  connection: Connection
  layout: Seat.Data[]
  fareClass: string
  segmentsCount: number
  currentCursor: number
  fareOptions: Option<string>[]
  setFareClass: (value: string) => void
  carIndex: number
  setCarIndex: (value: number) => void
  disableFare?: boolean
  reservedSeatsCount: number
  onSelect: (seat: Seat.Entry) => void
  onSubmit: (passengerCard?: DiscountCard.Item | null) => void
  selectedSeats: Seat.Entry[]
  onNavigateBack?: () => void
  maxSegmentCount: number
}

export const TrainSeatSelectionContext = createContext<TrainSeatSelectionContext>(null as any)
